<template>
	<div class="padding-top-10 edit-contain">
		<top :bgWhite='true'></top>

		<div class="contents  padding-bottom-20 ">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item>发布动态</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="bg-white margin-top-20 padding-lr-50 padding-bottom-20">
				<div class="  padding-top-20 fs24  padding-bottom-15">
					上传内容
				</div>
				<!-- <div class="flex align-center justify-center   height200"> -->
					<el-upload
				  ref="quillUploader"
				  class="avatar-uploader"
				  :action="BaseUrl+'/api/v1/5fc615f7da92b'"
				  :show-file-list="false"
				  :on-success="onSuccessQuill"
				  :limit="20"
				  :multiple="true"
				  >
				</el-upload>
				<div class="">
					<vue-ueditor-wrap  editor-id="xiumi-demo-01"  @before-init="addXiumiDialog" v-model="form.content" :config="myConfig"></vue-ueditor-wrap>
				</div>
					<!-- <quill-editor ref="text" v-model="form.content" class="myQuillEditor w100"
						:options="editorOption" /> -->
				<!-- </div> -->
				<div class="margin-top-20 fs24  padding-bottom-15">
					上传图片
					<span class="text-999 fs12">（最多上传9张照片）</span>
				</div>
				<!-- <oss-upload ref="ossupload"></oss-upload> -->
				<el-upload
				  class="margin-top-30"
				  :action="BaseUrl+'/api/v1/5fc615f7da92b'"
				  list-type="picture-card"
				  :on-preview="handlePictureCardPreview"
				  :on-remove="handleRemove"
				  :on-change="handleChange"
				  :limit="9"
				  :file-list="fileList">
				  <i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
				<div class=" margin-top-70 margin-bottom-20 flex align-center justify-center">
					<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
						@click="submitForm()"> 发布</span>
				</div>
			</div>
			

		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	import ossUpload from '@/components/oss-upload/oss-upload.vue'
		import ImageResize from 'quill-image-resize-module'
		import VueUeditorWrap from "vue-ueditor-wrap";
	Quill.register('modules/imageResize', ImageResize)
	export default {
		name: 'editArticle',
		components: {
			bottom,
			top,
			quillEditor,
			ossUpload,
			VueUeditorWrap
		},
		data() {
			return {
				dialogImageUrl: '',
				fileList:[],
				myConfig: {
        // 设置编辑器不自动被内容撑高
        autoHeightEnabled: true,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: "800",
        // 可以放后台存放路径
				// serverUrl: "https://www.xcrck.com/admin.php/admin/upload/get_ueditor", 
        // UEditor 是文件的存放路径，
        UEDITOR_HOME_URL: "/UEditor/",
				catchRemoteImageEnable: true, // 抓取远程图片
      },
				dialogVisible: false,
				BaseUrl:this.imgUrl,
				type:1, //1  动态  2 文章   3电子书
				content: '', //富文本内容
				editorOption: {
					placeholder: "请输入正文",
					modules: {
						toolbar: {
							container: [
								["bold", "italic", "underline", "strike"],
								[{
									color: []
								}, {
									background: []
								}],
								["link", "image"],
								["blockquote", "code-block"],
								[{
									align: []
								}],
								[{
									list: "ordered"
								}, {
									list: "bullet"
								}],
								[{
									script: "sub"
								}, {
									script: "super"
								}],
								[{
									header: 1
								}, {
									header: 2
								}],
								[{
									header: [1, 2, 3, 4, 5, 6, false]
								}],
								[{
									size: ["small", false, "large", "huge"]
								}],
								[{
									font: []
								}],
								[{
									indent: '-1'
								}, {
									indent: '+1'
								}],
							],
							handlers: {
								image: function(value) {
									if (value) {
										// 触发element-ui的文件上传
										document.querySelector(".avatar-uploader input").click();
									} else {
										this.quill.format("image", false);
									}
								},
							},
						},
						imageResize: {
							displayStyles: {
								backgroundColor: "black",
								border: "none",
								color: "white",
							},
							modules: ["Resize", "DisplaySize", "Toolbar"],
						},
					},
				},
				imageUrl: '', //封面
				form: {
					title: '', //标题
					content: '', //文章内容
					is_my: true,
					imageUrl: '', //封面
					artileType: '', //文章类型
					is_first: true, //是否首发
					is_pay: true, //是否付费
				}
			}
			// editorOption里是放图片上传配置参数用的，例如：
			// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
			// methods: 'post',  // 必填参数 图片上传方式
			// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
			// name: 'upload_file',  // 必填参数 文件的参数名
			// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
			// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
		},
		mounted() {
			this.type = this.$route.query.type;
			// this.$refs.ossupload.init()
		},
		created(){
			
		},
		methods: {
addXiumiDialog(editorId) {
      window.UE.registerUI(
        'xiumi-dialog',
        (editor, uiName) => {
          // 创建 “秀米弹窗”
          const dialog = new window.UE.ui.Dialog({
            // 注意：这是 xiumi-ue-dialog-v5.html 文件的访问链接，这个页面会通过 iframe 的方式嵌入到弹窗里
            iframeUrl: '/UEditor/xiumi/xiumi-ue-dialog-v5.html',
            editor,
            name: uiName,
            title: '秀米图文消息助手',
            cssRules: 'width: ' + (window.innerWidth - 200) + 'px; height: ' + (window.innerHeight - 200) + 'px;',
          });

          // 添加自定义按钮用于唤起“秀米弹窗”
          const btn = new window.UE.ui.Button({
            name: 'xiumi-connect',
            title: '秀米',
            cssRules: `background-image: url('//dl.xiumi.us/connect/ue/xiumi-connect-icon.png') !important; background-size: contain;`,
            onclick() {
              dialog.render();
              dialog.open();
            },
          });

          return btn;
        },
        0 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
        editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
      )
    },
			onChangeQuill(file, fileList) { 
			  console.log(file,fileList);
			  // let fileName = file.uid + file.name
			  // cosUtils.putObject(fileName, file.raw, 'operate/', (err, data) => { // 新闻图片存到operate/目录下
			  //   console.log(err || data)
			  //   if (!err) { 
			  //     let quill = this.$refs.myQuillEditor.quill
			  //     let length = quill.getSelection().index
			  //     // 图片上传到对象存储后的具体地址
			  //     let imgSrc = `https://xiangc.oss-cn-beijing.aliyuncs.com/uploads/operate/${fileName}`
			  //     quill.insertEmbed(length, "image", imgSrc)
			  //     // 调整光标到最后
			  //     quill.setSelection(length + 1)
			  //   }
			  // })
			},
			onSuccessQuill(res, file){
				console.log(22,res,file);
				let quill = this.$refs.text.quill;
				let length = quill.getSelection().index;
				let imgSrc = res.data.data.file;
				console.log('图片链接',imgSrc);
				quill.insertEmbed(length, "image", imgSrc)
				// 调整光标到最后
				quill.setSelection(length + 1)
			},
			onEditorChange(event) {
				event.quill.deleteText(20000, 1);
				if (this.form.body === 0) {
					this.TiLength = 0
				} else {
					this.TiLength = event.quill.getLength() - 1
				}
			},

			handleChange(file,fileList){
				console.log('文件列表',fileList);
				this.fileList = fileList;
			},
			// 删除
			handleRemove(file,fileList) {
				this.fileList = fileList;
			},
			// 大图预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			// 上传成功回调
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			// 上传封面
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt10M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt10M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isLt10M;
			},
			submitForm() {
				if(!this.form.content){
					this.$message.error('请输入正文内容');
					return;
				};
				let images = this.fileList.map(item=>{
					return item.response.data.data.fileId
				});
				let imgurl = '';
				if(images.length>0){
					 imgurl = images.join(',')
				};
				console.log( this.form.content);
				this.ajax('post', '/v1/talents/publish_document', {
					ducoment_type:1,
					body: this.form.content ,//文章内容
					is_free: 1 ,//是否免费
					images:imgurl
				}, res => {
					if (res.code == 1) {
						this.$message.success('发布成功');
						this.$router.go(-1);//返回上一层
					} else {
						this.$message.error(res.msg);
					}
				})
				// this.$router.push({
				// 	path: '/memberDate'
				// })
				// console.log(this.$refs.text.value)
			},
		}
	}
</script>

<style scoped>
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}

	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		padding-right: 30px;
	}

	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.el-form-item {
		display: flex;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
</style>
